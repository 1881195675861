import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Utils from '../../js/Utils';

function BreadcrumbComponent({ history, location }) {
    const redirect = () => {
        history.push("/");
    };
    let activeRoute = location && location.pathname ? Utils.capitalizeFirstLetter(location.pathname.replace("/", "")).replace("/", "") : '';
    activeRoute = activeRoute ? activeRoute.split('-') : '';
    let activeRoute1 = activeRoute && activeRoute.length && activeRoute[0] ? activeRoute[0] : '';
    let activeRoute2 = activeRoute && activeRoute.length && activeRoute[1] ? Utils.capitalizeFirstLetter(activeRoute[1]) : '';
    let finalRoute = activeRoute1 + " " + activeRoute2;
    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem><a onClick={redirect} className="hand-icon">Home</a></BreadcrumbItem>
                <BreadcrumbItem active>{finalRoute}</BreadcrumbItem>
            </Breadcrumb>
        </>
    );
};

export default withRouter(BreadcrumbComponent);
