import ReactModal from 'react-modal';
import Common from '../../js/common';
import { Col, Row } from 'reactstrap';
import Loader from 'react-loader-spinner';

function CustomLoader({ showLoader = false, loaderMessage = "Loading..." }) {
    return (
        <ReactModal
            ariaHideApp={true}
            isOpen={showLoader}
            role="dialog"
            style={Common.customResultPanelCssWarningModal}
        >
            <div className="modal-content bg-black custom-modal-content">
                <div className="modal-body">
                    <Col sm={12} className="center-align">
                        <Row>
                            <Loader visible={showLoader} type="TailSpin" color="#00BFFF" height={30} width={30} />
                            <p className="loader-message">{loaderMessage}</p>
                        </Row>
                    </Col>
                </div>
            </div>
        </ReactModal>
    );
}

export default CustomLoader;
