let common = {
	customResultPanelCss: {
		content: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			overflow: 'auto',
			outline: 'none',
			padding: '0px',
			width: '30%',
			transform: 'translate(-50%, -50%)',
			border: 0,
			background: 'transparent',
			borderRadius: '0'
		}
	},
	customResultPanelCssWarningModal: {
		content: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			overflow: 'auto',
			outline: 'none',
			padding: '0px',
			width: '15%',
			transform: 'translate(-50%, -50%)',
			border: 0,
			background: 'black'
		}
	},
	customResultPanelCssVideoModal: {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgba(0, 0, 0, 0.8)'
		},
		content: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			overflow: 'auto',
			outline: 'none',
			padding: '0px',
			width: '41%',
			transform: 'translate(-50%, -50%)',
			border: 0,
			background: 'transparent',
			borderRadius: '0'
		}
	},
	customResultPanelCssWarningModal2: {
		content: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			overflow: 'auto',
			outline: 'none',
			padding: '0px',
			width: '20%',
			transform: 'translate(-50%, -50%)',
			border: 0,
			background: 'black'
		}
	},
	fetchBase: (url, options) => {
		return new Promise((resolve, reject) => {
			fetch(url, options).then((response) => {
				response.text().then(function (text) {
					try {
						if (text && text.length > 0) {
							var json = JSON.parse(text);
							resolve(json);
						}
					}
					catch (error) {
						reject(text);
					}
				})
			})
		})
	},
	getFormData(data, forSort) {
		let formData = new FormData();
		Object.keys(data).forEach(function (key) {
			let value = data[key];
			if (typeof value == "object") {
				if (forSort && value) {
					let innerKey = Object.keys(value)[0];
					formData.append(key + "[" + innerKey + "]", value[innerKey]);//need data in form like sort[Date]=desc
				}
				else {
					formData.append(key, JSON.stringify(value));
				}
			}
			else {
				formData.append(key, value);
			}
		})
		return formData;
	}
};

export default common;