import React, { useEffect } from 'react';
import Utils from '../js/Utils';
import { Card, Col, Row } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import ImageViewer from 'react-images-viewer';

function PhotoGallery() {
    const [isOpen, setIsOpen] = React.useState(false);
    const [imgIndex, setImgIndex] = React.useState(0);
    const [currentGalleryData, setCurrentData] = React.useState([]);

    let total = Utils.photoGallery.length, pageSize = 9, currentPage = 1;
    let offset = (currentPage - 1) * pageSize;
    let currentData = Utils.photoGallery.slice(offset, offset + pageSize);

    useEffect(() => {
        setCurrentData(currentData)
    }, []);

    const gotoPrevImg = () => {
        setImgIndex(imgIndex - 1);
    };

    const gotoNextImg = () => {
        setImgIndex(imgIndex + 1);
    };

    const openImageViewer = (item) => {
        let imageIndex = Utils.galleryImages.findIndex(e => e.src === item.image);
        setImgIndex(imageIndex);
        setIsOpen(true);
    };

    const closeImageViewer = () => {
        setImgIndex(0);
        setIsOpen(false);
    };

    const handlePageClick = (params) => {
        currentPage = params.selected + 1;
        offset = (currentPage - 1) * pageSize;
        currentData = Utils.photoGallery.slice(offset, offset + pageSize);
        setCurrentData(currentData)
    };

    return (
        <>
            <Col sm={12} className='mt-1em'>
                <Row>
                    {
                        currentGalleryData && currentGalleryData.map((item, index) => {
                            return (
                                <Col sm={6} md={4} key={index} className='gallery-container'>
                                    <Card className="no-border">
                                        <img src={item.image} alt="Card cap" className="gallery-image" />
                                        <div className="overlay" onClick={() => openImageViewer(item)}>
                                            <img src="/assets/images/Icons/Media/Photos/camera-icon.png" alt="Card cap" className='mt-4em hand-icon' />
                                            <p className="title-text">{item.title}</p>
                                            <p className="subtitle-text">{item.subTitle}</p>
                                        </div>
                                    </Card>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Col>
            <Col md={12} className="text-center pb-1em">
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={<span>...</span>}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(total / pageSize)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    containerClassName={"pagination pagination-container"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    onPageChange={handlePageClick}
                />
            </Col>
            <ImageViewer
                imgs={Utils.galleryImages}
                isOpen={isOpen}
                onClickPrev={gotoPrevImg}
                onClickNext={gotoNextImg}
                onClose={closeImageViewer}
                currImg={imgIndex}
                closeBtnTitle={'Close'}
                leftArrowTitle={'Left'}
                rightArrowTitle={'Right'}
                showImgCount={false}
                theme={{
                    img: { height: '18em' }
                }}
            />
        </>
    );
}

export default PhotoGallery;
