import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";

import './index.css';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import reportWebVitals from './reportWebVitals';
import store from './app/store/configureStore';
import Routes from './app/routes';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
