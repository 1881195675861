import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import Utils from '../js/Utils';
import ReactPaginate from 'react-paginate';
import VideoPlayerModal from './VideoPlayerModal';

function InterviewVideos () {
    const [showVideoPlayer, setShowVideoPlayer] = React.useState(false);
    const [videoIndex, setVideoIndex] = React.useState(0);
    let total = Utils.interviewVideoThumbnailsCol1.length, pageSize = 9;

    const showVideoPopup = (index) => {
        setVideoIndex(index);
        setShowVideoPlayer(true);
    };

    const closeVideoPopup = () => {
        setShowVideoPlayer(false);
    };

    return (
        <div className='mt-1em'>
            <Col sm={12}>
                <Row>
                    {
                        Utils.interviewVideoThumbnailsCol1.map((item, index) => {
                            return (
                                <Col sm={4} key={index} className='gallery-container'>
                                    <Card className="no-border">
                                        <img src={item.thumbnail} alt="Card cap" className="gallery-image" />
                                        <div className="overlay" onClick={() => showVideoPopup(index)}>
                                            <img src="/assets/images/Icons/Media/Interview-Videos/play-circle-Icon.png" alt="Card cap" className='mt-4em hand-icon' />
                                            <p className="title-text">{item.title}</p>
                                            <p className="subtitle-text">{item.subTitle}</p>
                                        </div>
                                    </Card>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Col>
            <Col md={12} className="text-center pb-1em">
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={<span>...</span>}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(total / pageSize)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    containerClassName={"pagination pagination-container"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                />
            </Col>
            {
                showVideoPlayer ?
                    <VideoPlayerModal showPopup={showVideoPlayer} closePopup={closeVideoPopup} activeVideoIndex={videoIndex} /> : null
            }
        </div>
    );
}

export default InterviewVideos;
