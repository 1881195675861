import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import { Col, Row } from 'reactstrap';
import Utils from '../js/Utils';
import ScrollTrigger from 'react-scroll-trigger';
import ResourceModal from './common/ResourceModal';
import { saveAs } from 'file-saver';
import CustomLoader from './common/CustomLoader';

ReactModal.setAppElement('#root');

const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    dots: true,
    nextArrow:
        <img src="/assets/images/Icons/Media/Photos/Right-arrow-Pagination-icon.png" alt="Right arrow" />,
    prevArrow:
        <img src="/assets/images/Icons/Media/Photos/Left-arrow-Pagination-icon.png" alt="Left arrow" />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

function HomePage({ history }) {
    const [videoIsOpen, setVideoOpen] = useState(false);
    const [regIsOpen, setRegOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    function openModal(modalName) {
        switch (modalName) {
            case 'videoPlay':
                setVideoOpen(true);
                break;
            case 'resourceRegistrationModal':
                setRegOpen(true);
                break;
            default:
                break;
        }
    };
    const navigate = (pathName) => {
        history.push(pathName);
    };
    function closeModal(modalName) {
        switch (modalName) {
            case 'videoPlay':
                setVideoOpen(false);
                break;
            case 'resourceRegistrationModal':
                setRegOpen(false);
                break;
            default:
                break;
        }
    };
    function downloadPdf() {
        setShowLoader(true);
        var oReq = new XMLHttpRequest();
        var URLToPDF = Utils.fileDownloadUrl + "/Plan or Perish Chapter 1.pdf";
        oReq.open("GET", URLToPDF, true);
        oReq.responseType = "blob";
        oReq.onload = function () {
            var file = new Blob([oReq.response], {
                type: 'application/pdf'
            });
            // Generate file download directly in the browser !
            setShowLoader(false);
            saveAs(file, "Plan or Perish Chapter 1.pdf");
        };
        oReq.send();
    };
    return (
        <>
            <div className="plan-perish-container">
                <header className='text-white mt-1em'>
                    <Col sm={12}>
                        <Row>
                            <p className="text-center header-items" onClick={() => navigate("/media")}>Media</p>
                            <p className="text-center header-items" onClick={() => openModal('resourceRegistrationModal')}>Resource</p>
                            <h2 className="text-uppercase text-center ft-1em header-items pt-5px">Raakesh Saraff</h2>
                            <p className="text-center header-items" onClick={() => navigate("/contact-author")}>Contact Author</p>
                        </Row>
                    </Col>
                </header>
                <div className="col-md-12 welcome-txt text-center text-white">
                    <h1 className="text-uppercase">Plan or Perish</h1>
                    <p className="tagline">7 Core Strategies that 93% Exporters Importers Overlook &amp; Stagnate</p>
                    <img src="/assets/images/PlanOrPerish.png" alt="Cheque in with every check-in" className="book-image" />
                    <img onClick={downloadPdf} className="download-free-chapter hand-icon" src="/assets/images/Icons/Header/Download_Free_Chapter.png" alt="Download free chapter" />
                    <div className="buy-now-on-amazon-container">
                        <a href="https://www.amazon.in/dp/B08LH3NPFN" target="_blank"><img className="buy-now-on-amazon" src="/assets/images/Icons/Header/Buy_now_on_amazon.png" alt="Buy now on amazon" /></a>
                    </div>
                    <div className="Pre-order-Copy-container">
                        <img src="/assets/images/Icons/Header/order-your-copy.png" alt="order your copy" className="pre-order-copy hand-icon" onClick={() => navigate("/pay")} />
                    </div>
                </div>
            </div>
            <section id="book" className="text-center text-white">
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <p>In <i><b>Plan or Perish</b></i>, Raakesh Saraff shares with you the pitfalls that stop almost 93 percent growth initiatives. He shares the experience gained from his export-import growth consulting work with over 24000 CXOs and entrepreneurs over the last twenty-four years. This book will help you avoid those pitfalls and cherry pick customized, proven, and successful strategies that are relevant to your business.</p>
                    </div>
                </div>
            </section>
            <section className="pt-0">
                <div className="row dark-bg pt-3 pb-3">
                    <div className="col-md-7 border mt-3 pt-3 mx-auto">
                        <h3 className="text-center color-white">This book is for you if you want to:</h3>
                        <ul className="list">
                            <li><i className="fa fa-check-circle-o" aria-hidden="true"></i> Double your profits year-on-year</li>
                            <li><i className="fa fa-check-circle-o" aria-hidden="true"></i> Choose your buyers, instead of chasing them</li>
                            <li><i className="fa fa-check-circle-o" aria-hidden="true"></i> Pick high-profit and high-growth buyers over the ones that stop you</li>
                            <li><i className="fa fa-check-circle-o" aria-hidden="true"></i> Find profitable and high-growth products over the ones that stop you</li>
                            <li><i className="fa fa-check-circle-o" aria-hidden="true"></i> Diversify into profitable and high-growth products without much investment</li>
                            <li><i className="fa fa-check-circle-o" aria-hidden="true"></i> Choose high-profit and high-growth suppliers over the ones that stop you</li>
                            <li><i className="fa fa-check-circle-o" aria-hidden="true"></i> Find profitable and high growth markets</li>
                            <li><i className="fa fa-check-circle-o" aria-hidden="true"></i> Weed out markets that stop you</li>
                            <li><i className="fa fa-check-circle-o" aria-hidden="true"></i> Quote the right prices</li>
                            <li><i className="fa fa-check-circle-o" aria-hidden="true"></i> Maximize export benefits and minimize import duty</li>
                        </ul>
                    </div>
                    <div className="col-md-10 text-center mt-3 mx-auto">
                        <p>Using the practical insights shared in this book, you can confidently take tough decisions while choosing ideal products, markets, buyers, and suppliers in order to double your profits year-on-year and weed out the ones stopping you.</p>
                        <p>Each strategy is presented with real experiences of successful CXOs or entrepreneurs	and will motivate the readers to follow these. It is a great opportunity for corporate
                        professionals and entrepreneurs to be inspired and take some tough, game changing decisions.</p>
                    </div>
                </div>
            </section>
            <section id="foreword">
                <div className="col-md-9">
                    <p className="para-hd">In this book, you’ll discover that your business growth reflects what is within you and not what is outside. Stagnant growth of a business is an outcome of elements of mindset like:</p>
                    <ul className="list">
                        <li><i className="fa fa-check-square-o" aria-hidden="true"></i> Overconfidence</li>
                        <li><i className="fa fa-check-square-o" aria-hidden="true"></i> Arrogance</li>
                        <li><i className="fa fa-check-square-o" aria-hidden="true"></i> Comfort zone</li>
                        <li><i className="fa fa-check-square-o" aria-hidden="true"></i> Risk averseness</li>
                        <li><i className="fa fa-check-square-o" aria-hidden="true"></i> Waiting for consensus</li>
                        <li><i className="fa fa-check-square-o" aria-hidden="true"></i> Indecisiveness</li>
                        <li><i className="fa fa-check-square-o" aria-hidden="true"></i> Ignorance</li>
                        <li><i className="fa fa-check-square-o" aria-hidden="true"></i> Lack of regular reviews of market intelligence</li>
                        <li><i className="fa fa-check-square-o" aria-hidden="true"></i> Not seeking professional help</li>
                    </ul>
                    <p className="para-hd text-left">Most of the time, we know what we need to do, but wait for the right time to act. Good news is that since the decision must be taken by us, we have the power to change that.</p>
                </div>
            </section>
            <ScrollTrigger onExit={() => closeModal('videoPlay')}>
                <section id="video-section" className="text-center">
                    {
                        !videoIsOpen ?
                            <img src="/assets/images/Icons/Header/Home_page_video_thumbnail.png" alt="Video thumbnail" className='home-page-video-thumbnail hand-icon' onClick={() => openModal('videoPlay')} />
                            : <div className="center-div">
                                <video autoPlay={true} width="750" height="500" controls className="no-outline">
                                    <source src={`${Utils.fileDownloadUrl}/Book_Trailer.mp4`} type="video/mp4" />
                                </video>
                            </div>
                    }
                </section>
            </ScrollTrigger>
            <section id="about">
                <div className="col-md-10 text-center mx-auto">
                    <h2 className="text-uppercase mb-5">RAAKESH SARAFF</h2>
                    <h3 className="mb-5 after-name-quote">“Data driven decision making is the game changing opportunity to transform business profits.”</h3>
                    <img src="/assets/images/Rakeesh-Saraff.jpg" className="img-fluid rounded-circle red-border mb-5 bg-rakeesh-saraff" alt="Rakeesh Saraff" />
                    <h3>Author Bio</h3>
                    <p>Raakesh is the Founder and Director of InfodriveIndia.com, world’s third data driven website started in 1996. He is an export-import growth coach, author and
                    entrepreneur. With his belief in giving back to society, he started Eximguru.com in 1996. This widely popular and free exim encyclopedia benefits 3.5 million visitors
                    every year.</p>
                    <p>His mission is to help corporate professionals and entrepreneurs grow their businesses. He is passionate about coaching, helping business owners overcome
                    obstacles that hinder growth, and leveraging big data to extract game-changing insights in the export-import business. His consultancy has created a landmark shift
                    in the businesses of his clients.</p>
                    <p>Raakesh’s vast experience in export-import growth consulting helped him decode the science behind achieving 100 percent growths with data, strategies, mindset
                    and team. He shares this science and some inspirational success stories of his clients in his book - Plan or Perish and aims to inspire those who want to grow their businesses.</p>
                </div>
            </section>
            <section id="testimonial" className="container testimonial-section">
                <Slider {...settings}>
                    {Utils.testimonialData.map((item, i) => {
                        return (
                            <div className="col-sm-12 text-center" key={i}>
                                <div className="row center-div">
                                    <p>{item.text}</p>
                                    <h4 className="font-weight-bold text-center">{item.name}</h4>
                                </div>
                                <h5 className="text-center">{item.designation}</h5>
                            </div>
                        )
                    })}
                </Slider>
            </section>
            {
                regIsOpen ?
                    <ResourceModal regIsOpen={regIsOpen} closePopup={() => closeModal("resourceRegistrationModal")} />
                    : null
            }
            {
                showLoader ?
                    <CustomLoader showLoader={showLoader} loaderMessage={"Downloading Pdf..."} />
                    : null
            }
        </>
    );
}

export default withRouter(HomePage);
