import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
const queryString = require('query-string');

function Paymentcallback({ history, location }) {
    let qryData = queryString.parse(location.search);
    const redirect = () => {
        history.push("/");
    };
    return (
        <>
            <header className='text-white bg-header'>
                <Col sm={12}>
                    <Row>
                        <Col sm={4} className="padL-1em">
                            <img src="/assets/images/Icons/Contact-Author/Logo.png" className="pt-10px hand-icon height-90" alt="Logo" onClick={redirect} />
                        </Col>
                        <Col sm={4}>
                            <h4 className="payment-header">
                                {
                                    qryData.success === "true" ?
                                        'Your order has been placed'
                                        : 'Oops'
                                }
                            </h4>
                        </Col>
                    </Row>
                </Col>
            </header>
            <div className="mt-1em">
                <Col sm={12}>
                    {
                        qryData.success === "true" ?
                            <p className='text-center'>Payment successful.</p>
                            : <p className='text-center'>Payment Status: Fail</p>
                    }
                    <p className='text-center'>
                        {
                            qryData.success === "false" ?
                                qryData.code && qryData.code === 227 ? 'Your payment has been declined by your bank. Please try again or use a different method to complete the payment.'
                                    : 'Something went wrong. Try again later' : ''
                        }
                    </p>
                </Col>
            </div>
        </>
    );
}

export default withRouter(Paymentcallback);