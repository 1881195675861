import ReactModal from 'react-modal';
import Common from '../js/common';
import { Col } from 'reactstrap';
import Slider from "react-slick";
import Utils from '../js/Utils';

function VideoPlayerModal({ showPopup = false, closePopup, activeVideoIndex }) {
    return (
        <ReactModal
            ariaHideApp={true}
            isOpen={showPopup}
            role="dialog"
            style={Common.customResultPanelCssVideoModal}
            overlayClassName="video-modal-overlay"
        >
            <div className="modal-content video-modal-content">
                <div className="modal-header video-modal-header">
                    <Col sm={12} md={12} lg={12}>
                        <button type="button" className="close no-outline pb-10px" aria-label="Close" onClick={closePopup}>
                            <img src="/assets/images/Icons/Media/Photos/Close-icon.png" alt="Close Icon" />
                        </button>
                    </Col>
                </div>
                <div className="modal-body pb-0">
                    <Slider
                        infinite={true}
                        speed={1000}
                        slidesToShow={1}
                        slidesToScroll={1}
                        autoplay={false}
                        autoplaySpeed={5000}
                        dots={false}
                        initialSlide={activeVideoIndex}
                        nextArrow={<img src="/assets/images/Icons/Media/Photos/Right-icon.png" alt="Right arrow" />}
                        prevArrow={<img src="/assets/images/Icons/Media/Photos/Left-icon.png" alt="Left arrow" />}
                    >
                        {
                            Utils.videoPopupData.map((item, index) => {
                                return (
                                    <video id="interview-videos" width="750" controls className="no-outline" key={index} controlsList="nodownload" disablePictureInPicture={true}>
                                        <source src={item.videoLink} type="video/mp4" />
                                    </video>
                                );
                            })
                        }
                    </Slider>
                </div>
            </div>
        </ReactModal>
    );
}

export default VideoPlayerModal;
