import { withRouter } from 'react-router-dom';

function Layout({ location, children }) {
    let currentPath = location.pathname.replace('#', '').toLowerCase();
    let showFooterLogo = currentPath !== "/resources" && currentPath !== "/pay" && currentPath !== "/media" && currentPath !== "/contact-author"
        && currentPath !== "/paymentcallback";
    return (
        <>
            {children}
            <footer>
                <div className="container text-center">
                    <p>Copyright &copy; {(new Date()).getFullYear()} PLAN OR PERISH. All Rights Reserved.</p>
                    {
                        showFooterLogo ?
                        <div className="col-12 col-md-3 mx-auto">
                            <a href="http://www.sgpublication.com" target="_blank"><img src="/assets/images/SG-Publishing-Logo-Dark.png" className="img-fluid" alt="SG-Publishing Logo" /></a>
                        </div> : null
                    }
                </div>
            </footer>
        </>
    );
}

export default withRouter(Layout);
