import React from 'react';
import ReactModal from 'react-modal';
import { Form, FormGroup, Input, Col, Button, Row } from 'reactstrap';
import WarningModal from './WarningModal';
import SuccessModal from './SuccessModal';
import Common from '../../js/common';
import Utils from '../../js/Utils';
import CustomLoader from './CustomLoader';

function ResourceModal({ regIsOpen = false, closePopup }) {
    const [name, setName] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [designation, setDesignation] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [showWarningPopup, setWarningPopup] = React.useState(false);
    const [warningMessage, setWarningMessage] = React.useState('');
    const [showSuccessPopup, setSuccessPopup] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);

    function onInputChange(event, field) {
        const { value } = event.target;
        switch(field) {
            case 'name':
                setName(value);
                break;
            case 'mobile':
                setMobile(value);
                break;
            case 'companyName':
                setCompanyName(value);
                break;
            case 'designation':
                setDesignation(value);
                break;
            case 'email':
                setEmail(value);
                break;
            default:
                break;
        }
    };
    async function handleSubmit() {
        if (!name || !mobile || !email) {
            setWarningPopup(true);
            setWarningMessage('Mandatory field entry missing');
            setTimeout(() => setWarningPopup(false), 5000);
            return;
        }
        if (!Utils.validation.number.test(mobile) || !Utils.validation.email.test(email) || mobile.length < 10) {
            setWarningPopup(true);
            setWarningMessage(`Invalid Entry for ${!Utils.validation.number.test(mobile) ? "mobile" : !Utils.validation.email.test(email) ? "email" : "mobile"}`);
            setTimeout(() => setWarningPopup(false), 5000);
            return;
        }
        setShowLoader(true);
        let url = Utils.serverUrl;
        let regResponse = await Common.fetchBase(url + '/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, name: name, mobile: mobile })
        });
        name && setName('');
        mobile && setMobile('');
        companyName && setCompanyName('');
        designation && setDesignation('');
        email && setEmail('');
        setShowLoader(false);
        if (regResponse.success) {
            setSuccessPopup(true);
            setTimeout(() => closePopup('resourceRegistrationModal'), 3000);
            return;
        }
        setWarningPopup(true);
        setWarningMessage('Something went wrong. Please try again later');
        setTimeout(() => setWarningPopup(false), 5000);
    };
    const closeSuccessPopup = () => {
        setSuccessPopup(false);
    };
    return (
        <>
            <ReactModal
                ariaHideApp={true}
                isOpen={regIsOpen}
                role="dialog"
                style={Common.customResultPanelCss}
            >
                <div className="modal-content custom-modal-content">
                    <div className="modal-header custom-modal-header">
                        <Col sm={12} md={12} lg={12}>
                            <button type="button" className="close no-outline" onClick={() => closePopup('resourceRegistrationModal')} aria-label="Close">
                                <div className="circle">
                                    <div className="before"></div>
                                    <div className="after"></div>
                                </div>
                            </button>
                        </Col>
                    </div>
                    <div className="modal-body pt-0 pb-25">
                        <Col sm={12}>
                            <h4>Registration</h4>
                            <Form>
                                <p className="required-field"><span className='required-star'>*</span> These fields are mandatory</p>

                                <FormGroup>
                                    <Input type="text" className="wishlist1-input w-100 form-control" placeholder="Name*" onChange={(e) => onInputChange(e, 'name')} value={name} valid={!!name} invalid={!!!name} />
                                </FormGroup>

                                <FormGroup>
                                    <Input type="number" className="wishlist1-input w-100 form-control" placeholder="Mobile No*" onChange={(e) => onInputChange(e, 'mobile')} value={mobile} valid={!!mobile} invalid={!!!mobile} />
                                </FormGroup>

                                <FormGroup row>
                                    <Col sm={6}>
                                        <Input type="text" className="wishlist1-input w-100 form-control" placeholder="Company name" onChange={(e) => onInputChange(e, 'companyName')} value={companyName} />
                                    </Col>
                                    <Col sm={6}>
                                        <Input type="text" className="wishlist1-input w-100 form-control" placeholder="Designation" onChange={(e) => onInputChange(e, 'designation')} value={designation} />
                                    </Col>
                                </FormGroup>

                                <FormGroup>
                                    <Input type="email" className="wishlist1-input w-100 form-control" placeholder="Email*" onChange={(e) => onInputChange(e, 'email')} value={email} valid={!!email} invalid={!!!email} />
                                </FormGroup>

                                {
                                    email ?
                                        <Row>
                                            <Col sm={12} className="d-flex center-align">
                                                <img src="/assets/images/Icons/Registration/Alert-icon.png" alt="Book order" className="email-warning-icon" />
                                                <p className="email-warning-text">Please ensure you type a <span className="bold-font">valid</span> and <span className="bold-font">correct id</span> as the link to resources page would be shared on this id only</p>
                                            </Col>
                                        </Row> : null
                                }

                                <div className="text-center">
                                    <Button type="button" className="book-order w-100" onClick={handleSubmit}>
                                        <img src="/assets/images/Icons/Pre-Order/Check-icon.png" alt="Book order" className="book-order-img" /> <span> Submit</span>
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </div>
                </div>
            </ReactModal>
            {
                showWarningPopup ?
                    <WarningModal showPopup={showWarningPopup} warningMessage={warningMessage} showServerError={warningMessage.indexOf("Something went wrong") !== -1} />
                    : null
            }
            {
                showSuccessPopup ?
                    <SuccessModal
                        showPopup={showSuccessPopup}
                        closePopup={closeSuccessPopup}
                        messageText1={"Thank you for registering with us!"}
                        messageText2={"The link to the resources page has been sent to your email id"}
                    />
                    : null
            }
            {
                showLoader ?
                    <CustomLoader showLoader={showLoader} />
                    : null
            }
        </>
    );

}

export default ResourceModal;
