import React from 'react';
import { Switch, Route } from "react-router-dom";

import HomePage from '../components/HomePage';
import Media from '../components/Media';
import Resource from '../components/Resource';
import Payment from '../components/Payment';
import ContactAuthor from '../components/ContactAuthor';
import PaymentCallback from '../components/PaymentCallback';
import Layout from '../components/common/Layout';

const PublicRoutesWithHeader = ({ component: DynamicComponent, ...props }) => {
    return <Layout {...props}>
        <DynamicComponent />
    </Layout>
}

function Routes() {
    return (
        <Switch>
            <Route exact={true} path="/" render={props => (PublicRoutesWithHeader({ component: HomePage, ...props }))} />
            <Route exact={true} path="/media" render={props => (PublicRoutesWithHeader({ component: Media, ...props }))} />
            <Route exact={true} path="/resources" render={props => (PublicRoutesWithHeader({ component: Resource, ...props }))} />
            <Route exact={true} path="/pay" render={props => (PublicRoutesWithHeader({ component: Payment, ...props }))} />
            <Route exact={true} path="/paymentcallback" render={props => (PublicRoutesWithHeader({ component: PaymentCallback, ...props }))} />
            <Route exact={true} path="/contact-author" render={props => (PublicRoutesWithHeader({ component: ContactAuthor, ...props }))} />
        </Switch>
    );
}

export default Routes;
