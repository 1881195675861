import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import BreadcrumbComponent from './common/BreadcrumbComponent';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import PhotoGallery from './PhotoGallery';
import InterviewVideos from './InterviewVideos';
import ResourceModal from './common/ResourceModal';

require("rc-tabs/dist/rc-tabs.css");

function Media({ history }) {
    const [activeTab, setActiveTab] = React.useState('0');
    const [regIsOpen, setRegOpen] = React.useState(false);
    
    const onTabChange = (tab) => {
		setActiveTab(tab)
    };
    const navigate = (pathName) => {
        history.push(pathName);
    };
    const openModal = () => {
        setRegOpen(true);
    };
    const closeRegModal = () => {
        setRegOpen(false);
    };
    
    return (
        <>
            <header className='text-white bg-header'>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} className="padL-1em">
                            <img src="/assets/images/Icons/Contact-Author/Logo.png" className="pt-10px hand-icon height-90" alt="Logo" onClick={() => navigate("/")} />
                        </Col>
                        <Col sm={6} className='text-white mt-1em'>
                            <Row>
                                <p className="ml-4em ft-20 hand-icon menu" onClick={() => navigate("/")}>Home</p>
                                <p className="ml-4em ft-20 hand-icon menu">Media</p>
                                <p className="ml-4em ft-20 hand-icon menu" onClick={openModal}>Resource</p>
                                <p className="ml-4em ft-20 hand-icon menu" onClick={() => navigate("/contact-author")}>Contact Author</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </header>
            <Container>
                <BreadcrumbComponent />

                <Tabs
					activeKey={activeTab}
					onChange={onTabChange}
					renderTabBar={() => <ScrollableInkTabBar className='scrollable-tab-bar' />}
					renderTabContent={() => <TabContent />}
				>
                    <TabPane tab={'Photo Gallery'} key={0}>
                        <PhotoGallery />
                    </TabPane>
                    <TabPane tab={'Interview Videos'} key={1}>
                        <InterviewVideos />
                    </TabPane>
                </Tabs>
            </Container>
            {
                regIsOpen ?
                    <ResourceModal regIsOpen={regIsOpen} closePopup={closeRegModal} />
                    : null
            }
        </>
    );
}

export default withRouter(Media);
