import ReactModal from 'react-modal';
import Common from '../../js/common';
import { Col } from 'reactstrap';

function SuccessModal({ showPopup = false, closePopup, messageText1, messageText2 }) {
    return (
        <ReactModal
            ariaHideApp={true}
            isOpen={showPopup}
            role="dialog"
            style={Common.customResultPanelCss}
        >
            <div className="modal-content custom-modal-content">
                <div className="modal-header custom-modal-header">
                    <Col sm={12} md={12} lg={12}>
                        <button type="button" className="close no-outline" aria-label="Close" onClick={closePopup}>
                            <div className="circle">
                                <div className="before"></div>
                                <div className="after"></div>
                            </div>
                        </button>
                    </Col>
                </div>
                <div className="modal-body success-modal-body">
                    <img src="/assets/images/Icons/Registration/Big-Check-icon.png" alt="Success icon" />
                    <div className="mt-1em">
                        <h4>{messageText1}</h4>
                        <p>{messageText2}</p>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
}

export default SuccessModal;
