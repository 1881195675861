import ReactModal from 'react-modal';
import Common from '../../js/common';
import { Col, Row } from 'reactstrap';

function WarningModal({ showPopup = false, warningMessage, showServerError = false }) {
    return (
        <ReactModal
            ariaHideApp={true}
            isOpen={showPopup}
            role="dialog"
            style={showServerError ? Common.customResultPanelCssWarningModal2 : Common.customResultPanelCssWarningModal}
        >
            <div className="modal-content bg-black custom-modal-content">
                <div className="modal-body">
                    <Col sm={12} className="center-align">
                        <Row>
                            <img src="/assets/images/Icons/Registration/Alert-icon-white.png" alt="Warning icon" className="warning-icon" />
                            <p className="warning-message">{warningMessage}</p>
                        </Row>
                    </Col>
                </div>
            </div>
        </ReactModal>
    );
}

export default WarningModal;
