import React from 'react';
import { Form, FormGroup, Input, Col, Button, Row, Label, Container } from 'reactstrap';
import Utils from '../js/Utils';
import { withRouter } from 'react-router-dom';
import common from '../js/common';
import WarningModal from './common/WarningModal';

function Payment({ history }) {
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [address2, setAddress2] = React.useState('');
    const [address3, setAddress3] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setStateVal] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [amountIn, setAmountIn] = React.useState('699');
    const [payBy, setPayBy] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [designation, setDesignation] = React.useState('');
    const [showWarningPopup, setWarningPopup] = React.useState(false);
    const [warningMessage, setWarningMessage] = React.useState('');
    const [copies, setCopies] = React.useState(1);

    const redirect = () => {
        history.push("/");
    };

    const onInputChange = (event, field) => {
        // TODO: code improvement
        const { value } = event.target;
        switch(field) {
            case 'name':
                setName(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'address2':
                setAddress2(value);
                break;
            case 'address3':
                setAddress3(value);
                break;
            case 'city':
                setCity(value);
                break;
            case 'state':
                setStateVal(value);
                break;
            case 'country':
                setCountry(value);
                break;
            case 'amountIn':
                setAmountIn(value);
                break;
            case 'payBy':
                setPayBy(value);
                break;
            case 'companyName':
                setCompanyName(value);
                break;
            case 'designation':
                setDesignation(value);
                break;
            case 'copies':
                setCopies(value);
                break;
            default:
                break;
        }
    }

    const handleSubmit = async () => {
        if (!name || !email || !phone || !address || !address2 || !city || !state || !country ||
            !amountIn || !payBy) {
            setWarningPopup(true);
            setWarningMessage('Mandatory field entry missing');
            setTimeout(() => setWarningPopup(false), 5000);
            return;
        }
        if (!Utils.validation.number.test(phone) || !Utils.validation.email.test(email) || phone.length < 10) {
            setWarningPopup(true);
            setWarningMessage(`Invalid Entry for ${(!Utils.validation.number.test(phone) || phone.length < 10) ? "phone" : "email"}`);
            setTimeout(() => setWarningPopup(false), 5000);
            return;
        }
        let userData = {
            name: name,
            email: email,
            phone: phone,
            companyName: companyName,
            designation: designation,
            address: address,
            address2: address2,
            city: city,
            state: state,
            country: country,
            amountIn: amountIn,
            address3: address3,
            noOfCopies: copies
        };
        let url = Utils.serverUrl;
        // Handling for URL.
        if (url && url.indexOf('/pay') > -1) {
            url = url.replace('/pay', '');
        }
        if (url && url.indexOf('commentcallback') > -1) {
            url = url.replace('commentcallback', 'com');
        }
        switch (payBy) {
            case 'PayTm':
                let paytmResponse = await common.fetchBase(url + "/processPaytm", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userData)
                });
                let payTmUrl = window.location.hostname === 'localhost' ? 'https://securegw-stage.paytm.in/order/process' : 'https://securegw.paytm.in/order/process';
                let details = {
                    action: payTmUrl,
                    params: paytmResponse
                };
                post(details);
                break;
            case 'PayPal':
                let paypalResponse = await common.fetchBase(url + "/processPayPal", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userData)
                });
                let { links } = paypalResponse;
                for (let i = 0; i < links.length; i++) {
                    if (links[i].rel === 'approval_url') {
                        window.location.href = links[i].href;
                    }
                }
                break;
            case 'CCAvenue':
                let ccAvenueResponse = await common.fetchBase(url + "/ccavRequestHandler", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userData)
                });
                let ccavDetails = {
                    action: ccAvenueResponse.action,
                    params: ccAvenueResponse.params
                };
                post(ccavDetails);
                break;
            default:
                break;
        }
    }

    const post = (details) => {
        const form = buildForm(details)
        document.body.appendChild(form)
        form.submit()
        form.remove()
    }

    const buildForm = ({ action, params }) => {
        const form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute('action', action)
        form.setAttribute("headers", {
            "Content-type": "multipart/form-data"
        })
        Object.keys(params).forEach(key => {
            const input = document.createElement('input')
            input.setAttribute('type', 'hidden')
            input.setAttribute('name', key)
            input.setAttribute('id', key)
            input.setAttribute('value', params[key])
            form.appendChild(input)
        })
        return form
    }

    return (
        <>
            <header className='text-white bg-header'>
                <Col sm={12}>
                    <Row>
                        <Col sm={4} className="padL-1em">
                            <img src="/assets/images/Icons/Contact-Author/Logo.png" className="pt-10px hand-icon height-90" alt="Logo" onClick={redirect} />
                        </Col>
                        <Col sm={4}>
                            <h4 className="payment-header">Book Your Order</h4>
                        </Col>
                    </Row>
                </Col>
            </header>
            <Container>
                <Form>
                    <p className="required-field"><span className='required-star'>*</span> These fields are mandatory</p>

                    <Label>Personal Information:</Label>
                    <FormGroup row>
                        <Col sm={4}>
                            <Input type="text" className="wishlist1-input form-control mb-10px" placeholder="*Full Name" value={name} valid={!!name} invalid={!!!name} onChange={(e) => onInputChange(e, 'name')} />
                        </Col>
                        <Col sm={4}>
                            <Input type="email" className="wishlist1-input form-control mb-10px" placeholder="*Email" value={email} valid={!!email} invalid={!!!email} onChange={(e) => onInputChange(e, 'email')} />
                        </Col>
                        <Col sm={4}>
                            <Input type="number" className="wishlist1-input form-control mb-10px disable-inner-scroll" placeholder="*Phone" value={phone} valid={!!phone} invalid={!!!phone} onChange={(e) => onInputChange(e, 'phone')} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={4}>
                            <Input type="text" className="wishlist1-input form-control mb-10px" placeholder="Company Name" value={companyName} onChange={(e) => onInputChange(e, 'companyName')} />
                        </Col>
                        <Col sm={4}>
                            <Input type="text" className="wishlist1-input form-control mb-10px" placeholder="Designation" value={designation} onChange={(e) => onInputChange(e, 'designation')} />
                        </Col>
                    </FormGroup>

                    <Label>Address:</Label>
                    <FormGroup row>
                        <Col sm={4}>
                            <Input type="text" className="wishlist1-input form-control mb-10px" placeholder="*Flat, House no., Building, Company, Apartment" value={address} valid={!!address} invalid={!!!address} onChange={(e) => onInputChange(e, 'address')} />
                        </Col>
                        <Col sm={4}>
                            <Input type="text" className="wishlist1-input form-control mb-10px" placeholder="*Area, Colony, Street, Sector, Village" value={address2} valid={!!address2} invalid={!!!address2} onChange={(e) => onInputChange(e, 'address2')} />
                        </Col>
                        <Col sm={4}>
                            <Input type="text" className="wishlist1-input form-control mb-10px" placeholder="Landmark eg Near Apollo Hospital" value={address3} onChange={(e) => onInputChange(e, 'address3')} />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col sm={4}>
                            <Input type="text" className="wishlist1-input form-control mb-10px" placeholder="*Town/City" value={city} valid={!!city} invalid={!!!city} onChange={(e) => onInputChange(e, 'city')} />
                        </Col>
                        <Col sm={4}>
                            <Input type="text" className="wishlist1-input form-control mb-10px" placeholder="*State" value={state} valid={!!state} invalid={!!!state} onChange={(e) => onInputChange(e, 'state')} />
                        </Col>
                        <Col sm={4}>
                            <Input type="text" className="wishlist1-input form-control mb-10px" placeholder="*Country" value={country} valid={!!country} invalid={!!!country} onChange={(e) => onInputChange(e, 'country')} />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col sm={4}>
                            <Label>Number of copies:</Label>
                            <Input type="number" className="wishlist1-input form-control mb-10px" placeholder="Number of Copies" value={copies} onChange={(e) => onInputChange(e, 'copies')} min={1} />
                        </Col>
                        <Col sm={4}>
                            <Label>Total Amount:</Label>
                            <Input type="number" className="wishlist1-input form-control mb-10px" placeholder="*Amount In" value={Number(copies) * 699} disabled={true} />
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col sm={4}>
                                <Label>Pay By<span className='required-star'>*</span>:</Label>
                            </Col>
                            <Col sm={8} className="row">
                                {
                                    Utils.payBy.map((item, i) => {
                                        return <div className="custom-control custom-radio radibtright pd-rt" key={i}>
                                            <input type="radio" id={item.id} name='radio1' value={item.value} className="custom-control-input" onClick={(e) => onInputChange(e, 'payBy')} />
                                            <label htmlFor={item.id} className="popup-radio-label custom-control-label pay-by">
                                                <img src={`/assets/images/${item.content}.png`} alt="payment Option" style={{ margin: item.margin }} />
                                            </label>
                                        </div>
                                    })
                                }
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup className="text-center mt-1em">
                        <Button type="button" className="book-order col-sm-6" onClick={handleSubmit}>Submit</Button>
                    </FormGroup>
                </Form>
            </Container>
            {
                showWarningPopup ?
                    <WarningModal showPopup={showWarningPopup} warningMessage={warningMessage} />
                    : null
            }
        </>
    );
}

export default withRouter(Payment);
