import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormGroup, Input, Col, Button, Row, Container, Label } from 'reactstrap';
import BreadcrumbComponent from './common/BreadcrumbComponent';
import WarningModal from './common/WarningModal';
import Utils from '../js/Utils';
import Common from '../js/common';
import SuccessModal from './common/SuccessModal';
import ResourceModal from './common/ResourceModal';
import CustomLoader from './common/CustomLoader';

function ContactAuthor({ history }) {
    const [name, setName] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [showWarningPopup, setWarningPopup] = React.useState(false);
    const [warningMessage, setWarningMessage] = React.useState('');
    const [showSuccessPopup, setSuccessPopup] = React.useState(false);
    const [regIsOpen, setRegOpen] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);

    const navigate = (pathName) => {
        history.push(pathName);
    };
    const onInputChange = (event, field) => {
        const { value } = event.target;
        switch (field) {
            case 'name':
                setName(value);
                break;
            case 'mobile':
                setMobile(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'message':
                setMessage(value);
                break;
            default:
                break;
        }
    };
    const handleSubmit = async () => {
        if (!name || !mobile || !email || !message) {
            setWarningPopup(true);
            setWarningMessage('Mandatory field entry missing');
            setTimeout(() => setWarningPopup(false), 5000);
            return;
        }
        if (!Utils.validation.number.test(mobile) || !Utils.validation.email.test(email) || mobile.length < 10) {
            setWarningPopup(true);
            setWarningMessage(`Invalid Entry for ${!Utils.validation.number.test(mobile) ? "mobile" : !Utils.validation.email.test(email) ? "email" : "mobile"}`);
            setTimeout(() => setWarningPopup(false), 5000);
            return;
        }
        setShowLoader(true);
        let url = Utils.serverUrl;
        let regResponse = await Common.fetchBase(url + '/contactAuthor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, name: name, mobile: mobile, message: message })
        });
        setShowLoader(false);
        if (regResponse.success) {
            setName('');
            setMobile('');
            setEmail('');
            setMessage('');
            setSuccessPopup(true);
            return;
        }
        setWarningPopup(true);
        setWarningMessage('Something went wrong. Please try again later');
        setTimeout(() => setWarningPopup(false), 5000);
    };
    const closeModal = (modalName) => {
        switch (modalName) {
            case 'successModal':
                setSuccessPopup(false);
                break;
            case 'regModal':
                setRegOpen(false);
                break;
            default:
                break;
        }
    };
    const openModal = () => {
        setRegOpen(true);
    };

    return (
        <>
            <header className='text-white bg-header'>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} className="padL-1em">
                            <img src="/assets/images/Icons/Contact-Author/Logo.png" className="pt-10px hand-icon height-90" alt="Logo" onClick={() => navigate("/")} />
                        </Col>
                        <Col sm={6} className='text-white mt-1em'>
                            <Row>
                                <p className="ml-4em ft-20 hand-icon menu" onClick={() => navigate("/")}>Home</p>
                                <p className="ml-4em ft-20 hand-icon menu" onClick={() => navigate("/media")}>Media</p>
                                <p className="ml-4em ft-20 hand-icon menu" onClick={openModal}>Resource</p>
                                <p className="ml-4em ft-20 hand-icon menu">Contact Author</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </header>
            <Container>
                <BreadcrumbComponent />

                <Row>
                    <Col sm={6}>
                        <h4>Get in Touch with Raakesh Saraff</h4>
                        <Form>
                            <p className="required-field"><span className='required-star'>*</span> These fields are mandatory</p>

                            <FormGroup>
                                <Label for="Name"><p className="custom-label">Your Name<span className='required-star'>*</span></p></Label>
                                <Input type="text" id="Name" className="wishlist1-input w-100 form-control" placeholder="Type Name" onChange={(e) => onInputChange(e, 'name')} value={name} valid={!!name} invalid={!!!name} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="Email"><p className="custom-label">Your Email<span className='required-star'>*</span></p></Label>
                                <Input type="email" id="Email" className="wishlist1-input w-100 form-control" placeholder="Type Email Address" onChange={(e) => onInputChange(e, 'email')} value={email} valid={!!email} invalid={!!!email} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="Mobile"><p className="custom-label">Your Mobile Number<span className='required-star'>*</span></p></Label>
                                <Input type="number" id="Mobile" className="wishlist1-input w-100 form-control" placeholder="Type mobile Number" onChange={(e) => onInputChange(e, 'mobile')} value={mobile} valid={!!mobile} invalid={!!!mobile} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="Message"><p className="custom-label">Your Message<span className='required-star'>*</span></p></Label>
                                <Input type="textarea" id="Message" className="wishlist1-input w-100 form-control" placeholder="Type Text" onChange={(e) => onInputChange(e, 'message')} value={message} valid={!!message} invalid={!!!message} />
                            </FormGroup>

                            <div className="text-center">
                                <Button type="button" className="book-order w-100" onClick={handleSubmit}>
                                    <img src="/assets/images/Icons/Contact-Author/Message-icon.png" alt="Send Message" className="height-18" /> <span className="ft-16"> Send Message</span>
                                </Button>
                            </div>
                            <Row className="social-icon-container">
                                <Col sm={2}>
                                    <a href="https://www.facebook.com/RaakeshSaraff" target="_blank"><img src="/assets/images/Icons/Contact-Author/Facebook.png" alt="Facebook" /></a>
                                </Col>
                                <Col sm={2}>
                                    <a href="https://linkedin.com/in/rakeshsarafinfodriveindia" target="_blank"><img src="/assets/images/Icons/Contact-Author/Linkedin.png" alt="Linkedin" /></a>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col sm={6}>
                        <div className="pdl-5em">
                            <img src="/assets/images/Rakeesh-Saraff.jpg" alt="Rakeesh-Saraff" />
                        </div>
                    </Col>
                </Row>
            </Container>
            {
                showWarningPopup ?
                    <WarningModal showPopup={showWarningPopup} warningMessage={warningMessage} showServerError={warningMessage.indexOf("Something went wrong") !== -1} />
                    : null
            }
            {
                showSuccessPopup ?
                    <SuccessModal
                        showPopup={showSuccessPopup}
                        closePopup={() => closeModal('successModal')}
                        messageText1={"Thank you for contacting me!"}
                        messageText2={"We will reach you soon."}
                    />
                    : null
            }
            {
                regIsOpen ?
                    <ResourceModal regIsOpen={regIsOpen} closePopup={() => closeModal('regModal')} />
                    : null
            }
            {
                showLoader ?
                    <CustomLoader showLoader={showLoader} />
                    : null
            }
        </>
    );
}

export default withRouter(ContactAuthor);
