import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import BreadcrumbComponent from './common/BreadcrumbComponent';
import Utils from '../js/Utils'; 

function Resource({ history }) {
    const redirect = (path) => {
        history.push(path);
    };
    return (
        <>
            <header className='text-white bg-header'>
                <Col sm={12}>
                    <Row>
                        <Col sm={6} className="padL-1em">
                            <img src="/assets/images/Icons/Contact-Author/Logo.png" className="pt-10px hand-icon height-90" alt="Logo" onClick={() => redirect("/")} />
                        </Col>
                        <Col sm={6} className='text-white mt-1em'>
                            <Row>
                                <p className="ml-4em ft-20 hand-icon menu" onClick={() => redirect("/")}>Home</p>
                                <p className="ml-4em ft-20 hand-icon menu" onClick={() => redirect("/media")}>Media</p>
                                <p className="ml-4em ft-20 hand-icon menu">Resource</p>
                                <p className="ml-4em ft-20 hand-icon menu" onClick={() => redirect("/contact-author")}>Contact Author</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </header>
            <Container>
                <BreadcrumbComponent />

                <Col sm={12} className="d-w-flex">
                    <Col sm={8}>
                        <Row>
                            {
                                Utils.activityData.map((item, index) => {
                                    return (
                                        <Col sm={12} className="d-flex" key={index}>
                                            <Col sm={1}>
                                                <img src="/assets/images/Icons/Resources/excel.png" alt="Excel" />
                                            </Col>
                                            <Col sm={9}>
                                                <p className="activity-text">{item.label}</p>
                                            </Col>
                                            <Col sm={2}>
                                                <a href={item.downloadLink} className="hand-icon"><img src="/assets/images/Icons/Resources/download.png" alt="Download Excel" className="hand-icon" /></a>
                                            </Col>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                    <Col sm={4}>
                        <img src="/assets/images/Icons/Resources/Book-Image.png" alt="Book" />
                        <a href={Utils.fileDownloadUrl + "/activities.zip"} className="hand-icon"><img src="/assets/images/Icons/Resources/Activity-Details.png" alt="Activity Details" className="hand-icon" /></a>
                    </Col>
                </Col>
            </Container>
        </>
    );
}

export default withRouter(Resource);
